/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * ======================================================================== */


(function ($) {

  // Global vars
  var tabletMedia = window.matchMedia('(max-width: 992px)');
  var mobileMedia = window.matchMedia('(max-width: 767px)');

  var libsPath = 'static/public/libs/';


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var main = {
    // All pages
    common: {

      init: function () {

        main.common.sideMenu();
        // main.common.contactTabs();

      },

      animation: function () {

        var tilesAnimation = {
          out: function (next) {

            if (tabletMedia.matches) {
              next();
              return;
            }

            $link = $('.home-tile__wrap.animate-this');

            if ($link.length === 0) {
              next();
              return;
            }

            var $tile = $link.parents('.home-tile__item');

            if ($tile.length === 0) {
              next();
              return;
            }

            var duration = 1;

            var $tileInner = $tile.find('.home-tile__wrap'),
              $tileLine = $tile.find('.home-tile__line'),
              tileInnerWidth = $tileInner.width();

            $('body').css('overflow', 'hidden');

            $tile.css('z-index', 99999);

            $tileInner.find('.home-tile__content').css({
              'width': tileInnerWidth,
              'left': 0,
              'right': 0,
              'margin': '0 auto',
            });

            $tileLine.css('min-height', '72px');

            $tileLine.find('i').css({
              'transform': 'scale(0.5)',
              'opacity': 0
            });


            TweenMax.to($tile.find('.home-tile__content, .home-tile__title'), .1, {
              opacity: 0
            });

            TweenMax.to($tile, duration, {
              left: -($('.home-aside').outerWidth()),
              right: '0',
              'padding-left': 125,
              'padding-right': 0,
              width: 'auto',
              height: '100%',
              onComplete: next
            });

            $tile.nextAll('.home-tile__item').each(function () {
              var percent = (4 - $(this).index()) * 25;
              TweenMax.to($(this), duration, {
                left: percent + '%'
              });
            })

          },
          in: function (next) {

            if (tabletMedia.matches) {
              next();
              return;
            }

            var $header = $('.header'),
              $headerInner = $header.find('.header__inner'),
              $otherContent = $header.nextAll(),
              $logo = $('.side-menu-toggle__logo .logo__image'),
              headerMinHeight = $header.css('min-height');

            $('body').css('overflow', 'auto');

            $header.css({
              'min-height': 72,
              'height': 72
            });

            $headerInner.css('opacity', 0);

            $otherContent.css('opacity', 0);

            $logo.css('opacity', 0);


            TweenMax.to($header, 0.8, {
              height: headerMinHeight,
              onComplete: function () {

                $header.css({
                  'min-height': headerMinHeight,
                  'height': 'auto'
                });

                next();
              }
            });

            TweenMax.to($logo, 1, {
              opacity: 1,
            });

            TweenMax.to($headerInner, 0.5, {
              opacity: 1,
              delay: 0.3
            });

            TweenMax.to($otherContent, 0.5, {
              opacity: 1,
              delay: 0.8
            });

          }
        };

        var headerOutAnimation = {
          out: function (next) {

            if (tabletMedia.matches) {
              next();
              return;
            }

            var $header = $('.header'),
              $headerBack = $header.find('.header__back'),
              $headerHeading = $header.find('.header__heading'),
              $headerText = $header.find('.header__text'),
              $headerBg = $header.find('.header__bg'),
              $otherContent = $header.nextAll(),
              $logo = $('.side-menu-toggle__logo .logo__image');

            TweenMax.to([$logo, $headerText, $otherContent, $headerBack], 0.2, {
              opacity: 0,
            });

            TweenMax.to($headerHeading, 0.4, {
              opacity: 0,
              delay: 0.2,
            });

            TweenMax.to($headerBg, 1.3, {
              left: '100%',
              delay: 0.2,
              onComplete: next
            });

          },
          in: function (next) {

            if (tabletMedia.matches) {
              next();
              return;
            }

            $('#swup').css('opacity', 0);
            TweenLite.to($('#swup'), .8, {
              opacity: 1,
              onComplete: next
            });

          }
        };

        var options = {
          // debugMode: true,
          animations: {
            '*': {
              out: function (next) {
                next()
              },
              in: function (next) {
                next()
              }
            },

            '*>initiate': tilesAnimation,
            '*>read': tilesAnimation,
            '*>experience': tilesAnimation,
            '*>implement': tilesAnimation,

            'initiate>homepage': headerOutAnimation,
            'read>homepage': headerOutAnimation,
            'experience>homepage': headerOutAnimation,
            'implement>homepage': headerOutAnimation,

          }
        };

        swupjs = new Swupjs(options);

      },

      sideMenu: function () {

        $('.side-menu-toggle, .overlay, .mobile-menu-toggle').click(function (e) {

          if ($(e.target).hasClass('.logo') || $(e.target).hasClass('logo__image')) {
            return;
          }

          $('.side-menu-toggle').toggleClass('side-menu-toggle--active');
          $('.overlay').toggleClass('overlay--active');
          $('.sidebar').toggleClass('sidebar--active');
          $('body').toggleClass('overflow');
          $('html').toggleClass('overflow');

        });

      },

      contactTabs: function () {

        function init() {

          $('.contact-block__tab-head').each(function () {

            var $cont = $(this).parents('.contact-block'),
              $heads = $(this).find('.contact-block__tab-title'),
              $tabs = $cont.find('.contact-block__tab');

            $heads.first().addClass('contact-block__tab-title--active');
            $tabs.not(':first-child').hide();

            $heads.click(function (e) {

              $(this)
                .parents('.contact-block__tab-head')
                .find('.contact-block__tab-title')
                .removeClass('contact-block__tab-title--active');

              $(this).addClass('contact-block__tab-title--active');

              $tabs.hide();
              $tabs.eq($(this).index()).show();

            });

          });

        }

        function destroy() {

          $('.contact-block__tab-head').each(function () {

            var $cont = $(this).parents('.contact-block');

            $(this)
              .find('.contact-block__tab-title')
              .removeClass('contact-block__tab-title--active');

            $cont.find('.contact-block__tab').show();

          });

        }

        function match(e) {
          if (e.matches) {
            init();
          } else {
            destroy();
          }
        }

        match(tabletMedia);
        tabletMedia.addListener(match);

      },

      SlideDown: function (args) {

        var $container = $(args.container),
          $items = $container.find(args.item),
          count = args.count,
          isOpen = args.isOpen;

        $container.addClass('slide-down');

        // Load more btn

        function triggerBtnText() {
          var text = 'Show ';
          text += isOpen ? 'less ' : 'more ';
          if (args.loadMoreText) {
            text += args.loadMoreText;
          }
          return text;
        }

        var $triggerBtn = $('<div>').addClass('read-more read-more--dark'),
          $triggerBtnHeading = $('<p>')
            .addClass('heading heading--uppercase read-more__text')
            .text(triggerBtnText()),
          $triggerBtnIcon = $('<i>').addClass('read-more__icon read-more__icon--dark');

        if (args.loadMoreCss) {
          $triggerBtn.css(args.loadMoreCss);
        }

        $triggerBtn.append($triggerBtnHeading, $triggerBtnIcon);

        function init() {

          $container.css('transition', '');
          $container.after($triggerBtn);

          var maxHeight = 0,
            height = 0;
          $items.each(function (index, el) {
            if (index < count) {
              maxHeight += $(this).outerHeight();
            }
            height += $(this).outerHeight();
          });

          if (args.offset) {
            maxHeight -= args.offset;
          }

          if (!isOpen) {
            $container.css('max-height', maxHeight);
            $container.removeClass('slide-down--opened');
          } else {
            $container.css('max-height', height);
            $container.addClass('slide-down--opened');
          }

          $triggerBtn.unbind('click');

          $triggerBtn.click(function () {

            $container.css('transition', '1s');

            if (!$container.hasClass('slide-down--opened')) {
              $container.css('max-height', height);
              $container.addClass('slide-down--opened');
              $triggerBtnIcon.addClass('read-more__icon--minus');
              isOpen = true;
            } else {
              $container.css('max-height', maxHeight);
              $container.removeClass('slide-down--opened');
              $triggerBtnIcon.removeClass('read-more__icon--minus');
              isOpen = false;
            }

            $triggerBtnHeading.text(triggerBtnText());

          });

        }

        function destroy() {
          $container.css('max-height', '');
          $triggerBtn.unbind('click');
          $triggerBtn.remove();
        }

        this.init = function () {
          init();
          $(window).resize(init);
        };

        this.destroy = function () {
          destroy();
          $(window).unbind('resize', init)
        };

      },

      tagBoxStripeLines: function () {
        $('.tag-box').each(function () {
          var boxWidth = $(this).width(),
            lineWidth = 0,
            $items = $(this).find('.tag-box__item');

          $items.removeClass('tag-box__item--remove-line');

          $items.each(function () {
            var itemWidth = $(this).width();
            lineWidth += itemWidth;
            if (lineWidth > boxWidth) {
              lineWidth = itemWidth;
              $(this).prev().addClass('tag-box__item--remove-line');
            }
          });
        });
      },

      subscriptionForm: function () {

        $('.subscribe-form .subscribe-form__inner')
          .append(main.common.loader('subscribe-form__loader'));

        $('.subscribe-form').on('submit', function (e) {

          e.preventDefault();

          var button = $('.subscribe-form__btn'),
            res = $('.subscribe-form__response'),
            $self = $(this);

          var email = $(this).find('input[name="email"]').val();

          if (!validateEmail(email)) {
            $self.addClass('subscribe-form--error');
            res.html('Please verify the e-mail is correct.');
            res.slideDown();
            return;
          }

          $self.addClass('subscribe-form--loading');
          button.prop('disabled', true);

          $.ajax({
            url: '/subscribe',
            data: new FormData($(this)[0]),
            cache: false,
            contentType: false,
            processData: false,
            type: 'POST',
            success: function (data) {
              if (data.error) {
                res.html(data.error);
                $self.addClass('subscribe-form--error');
              } else {
                res.html(data.result);
                $self.removeClass('subscribe-form--error');
                $self.trigger("reset");
              }
              res.slideDown();
            },
            error: function () {
              res.html('We have technical problem. Please try again later.');
              $self.addClass('subscribe-form--error');
              res.slideDown();
            },
            complete: function () {
              button.prop('disabled', false);
              $self.removeClass('subscribe-form--loading');
            }
          });
        });

      },

      accordion: function () {

        var allPanels = $('.accordion .accordion__content').hide();
        $('.accordion__head').click(function () {
          var $item = $(this).parents('.accordion__item'),
            $content = $item.find('.accordion__content');

          if ($item.hasClass('accordion__item--active')) {
            $item.removeClass('accordion__item--active');
            allPanels.slideUp();
            return false;
          } else {
            $('.accordion .accordion__item')
              .removeClass('accordion__item--active');
            $item.addClass('accordion__item--active');
            allPanels.slideUp();
            $content.slideDown();
          }
        });

      },

      locationsMap: function () {

        $('.locations__list-item').hover(function () {
          var markerId = $(this).data('marker'),
            $marker = $('#' + markerId),
            $markerHover = $('#' + markerId + '_hover');
          if ($marker.length === 0) {
            return false;
          }
          $marker.addClass('locations__map-dot--active');
          if ($markerHover.length > 0) {
            $markerHover.attr('visibility', 'visible');
          }
        }, function () {
          var markerId = $(this).data('marker'),
            $marker = $('#' + markerId),
            $markerHover = $('#' + markerId + '_hover');
          $marker.removeClass('locations__map-dot--active');
          if ($markerHover.length > 0) {
            $markerHover.attr('visibility', 'hidden');
          }
        });

        $('.locations__map-dot').hover(function () {
          var markerId = $(this).attr('id'),
            $text = $('.locations__list-item[data-marker="' + markerId + '"]'),
            $markerHover = $('#' + markerId + '_hover');
          $(this).addClass('locations__map-dot--active');
          if ($markerHover.length > 0) {
            $markerHover.attr('visibility', 'visible');
          }
          if ($text.length > 0) {
            $text.addClass('locations__list-item--active');
          }
        }, function () {
          var markerId = $(this).attr('id'),
            $text = $('.locations__list-item[data-marker="' + markerId + '"]'),
            $markerHover = $('#' + markerId + '_hover');
          $(this).removeClass('locations__map-dot--active');
          if ($markerHover.length > 0) {
            $markerHover.attr('visibility', 'hidden');
          }
          if ($text.length > 0) {
            $text.removeClass('locations__list-item--active');
          }
        });

      },

      dropdown: function () {

        $(".drop-down .drop-down__placeholder").click(function () {
          var $dropdown = $(this).parents('.drop-down');
          $dropdown.find(".drop-down__options").slideToggle();
          if ($dropdown.hasClass('drop-down--open')) {
            $dropdown.removeClass('drop-down--open');
          } else {
            $dropdown.addClass('drop-down--open');
          }
        });

        $(".drop-down .drop-down__option").click(function () {

          var $dropdown = $(this).parents('.drop-down');

          if (!$dropdown.hasClass('drop-down--links') && !$(this).hasClass('drop-down__option--empty')) {
            var text = $(this).text();
            $dropdown.find(".drop-down__placeholder").html(text);
            $dropdown.addClass('drop-down--selected');
          }

          $dropdown.find(".drop-down__options").slideUp();
          $dropdown.removeClass('drop-down--open');


        });

        $(document).bind('click', function (e) {

          var $clicked = $(e.target);
          if (!$clicked.parents().hasClass("drop-down")) {

            $(".drop-down .drop-down__options").slideUp();
            $(".drop-down").removeClass('drop-down--open');

          } else {

            var $current = $clicked.parents('.drop-down');

            $(".drop-down").not($current)
              .removeClass('drop-down--open')
              .find(".drop-down__options")
              .slideUp();

          }

        });

      },
      parseTwitterDate: function (tdate) {

        var systemDate = new Date(Date.parse(tdate));
        var userDate = new Date();
        var diff = Math.floor((userDate - systemDate) / 1000);
        if (diff <= 1) {
          return 'just now';
        }
        if (diff < 20) {
          return diff + ' seconds ago';
        }
        if (diff < 40) {
          return 'half a minute ago';
        }
        if (diff < 60) {
          return 'less than a minute ago';
        }
        if (diff <= 90) {
          return 'one minute ago';
        }
        if (diff <= 3540) {
          return Math.round(diff / 60) + ' minutes ago';
        }
        if (diff <= 5400) {
          return '1 hour ago';
        }
        if (diff <= 86400) {
          return Math.round(diff / 3600) + ' hours ago';
        }
        if (diff <= 129600) {
          return '1 day ago';
        }
        if (diff < 604800) {
          return Math.round(diff / 86400) + ' days ago';
        }
        if (diff <= 777600) {
          return '1 week ago';
        }

        var systemDateOptions = {month: 'long', day: 'numeric'};
        if (systemDate.getFullYear() < userDate.getFullYear()) {
          systemDateOptions['year'] = 'numeric';
        }
        return systemDate.toLocaleDateString("en-US", systemDateOptions);
      },

      loader: function (className) {

        var $wrap = $('<div>').addClass('sk-fading-circle');
        if (className) {
          $wrap.addClass(className);
        }

        for (var i = 0; i <= 12; i++) {
          var $circle = $('<div>').addClass('sk-circle sk-circle' + i);
          $wrap.append($circle);
        }

        return $wrap;

      },

    },

    home_page: {
      init: function () {

        $('.home-tile__wrap').click(function () {
          $(this).addClass('animate-this');
        })

      }
    },

    whatis_page: {
      init: function () {

        main.common.tagBoxStripeLines();
        $(window).resize(main.common.tagBoxStripeLines);

        main.common.subscriptionForm();

      }
    },

    about_page: {
      init: function () {

        // Load latest tweets

        $.ajax({
          type: 'POST',
          url: '/twitter',
          success: function (data) {

            if (!data) {
              return;
            }

            twitterResponse = JSON.parse(data);

            var $tweetsHolder = $('.tweets .tweets__inner');

            $.each(twitterResponse, function (index, tweet) {

              var $item = $('<div>').addClass('tweets__item tweet'),
                $date = $('<div>').addClass('tweet__date'),
                $content = $('<div>').addClass('tweet__content'),
                $link = $('<a>').addClass('tweet__link');

              $date.text(main.common.parseTwitterDate(tweet.created_at));

              content = tweet.retweeted_status !== undefined ? tweet.retweeted_status.full_text : tweet.full_text;

              content = content.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
              content = content.replace(/@ThalesHBS/, '');
              content = content.replace(/\[\d links\]/, '');


              $content.html(content);

              $link
                .text('Read More')
                .attr({
                  'href': 'https://twitter.com/ThalesHBS/status/' + tweet.id_str,
                  'target': '_blank'
                });

              $item.append($date, $content, $link);

              $tweetsHolder.append($item);

            });


            // Tweets load more btn
            tweetsSlideDown = new main.common.SlideDown({
              container: '.tweets',
              item: '.tweets__item',
              loadMoreText: 'tweets',
              count: 2,
              isOpen: false,
              offset: 2,
              loadMoreCss: {
                'margin-top': '13px'
              }
            });

            if (mobileMedia.matches) {
              tweetsSlideDown.init();
            }

            mobileMedia.addListener(function (e) {
              if (e.matches) {
                tweetsSlideDown.init();
              } else {
                tweetsSlideDown.destroy();
              }
            });

          }
        });


      }
    },

    initiate_page: {
      init: function () {

        // Brands load more
        brandSlideDown = new main.common.SlideDown({
          container: '.brand-grid',
          item: '.brand-grid__item',
          loadMoreText: 'talks',
          count: 8,
          isOpen: false,
          loadMoreCss: {
            'margin-top': '13px'
          }
        });

        if (mobileMedia.matches) {
          brandSlideDown.init();
        }

        mobileMedia.addListener(function (e) {
          if (e.matches) {
            brandSlideDown.init();
          } else {
            brandSlideDown.destroy();
          }
        });

      }
    },

    experience_page: {

      init: function () {

        main.common.accordion();
        main.common.locationsMap();

        //loadCSS(libsPath + 'simplebar/simplebar.css');
        //$.loadCachedScript( libsPath + "simplebar/simplebar.js")
        //  .done(function (script, textStatus) {
        //    new SimpleBar($('.locations__list')[0]);
        //  })
        //  .fail(function (jqxhr, settings, exception) {
        //  });

      }

    },

    read_page: {
      init: function () {

        // custom drop-down

        main.common.dropdown();

        loadCSS(libsPath + 'owl.carousel/dist/assets/owl.carousel.min.css');
        loadCSS(libsPath + 'owl.carousel/dist/assets/owl.theme.default.min.css');

        $.loadCachedScript(libsPath + "owl.carousel/dist/owl.carousel.min.js")
          .done(function (script, textStatus) {

            $('.owl-carousel.testimonials').owlCarousel({
              loop: true,
              margin: 10,
              dots: true,
              responsive: {
                0: {
                  items: 1,
                },
                767: {
                  items: 2,
                },
                992: {
                  items: 3,
                }
              }
            }).on('initialized.owl.carousel', function (event) {
            })

          })
          .fail(function (jqxhr, settings, exception) {
          });

      }
    }

  };


  var UTIL = {
    fire: function (func, funcname, args) {
      var namespace = main;
      funcname = (funcname === undefined) ? 'init' : funcname;
      if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      UTIL.fire('common');
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
      });
    }
  };

  // Initialization

  $(document).ready(UTIL.loadEvents);
  $(document).ready(main.common.animation);

  document.addEventListener('swup:contentReplaced', function (event) {

    if( window.dataLayer !== undefined && window.dataLayer !== [] ){
      window.dataLayer.push({
        'event': 'VirtualPageview',
        'virtualPageURL': window.location.pathname + window.location.search,
        'virtualPageTitle': document.title
      });
    }


    $('html, body').removeClass('overflow');
    UTIL.loadEvents();
  });

  // Utils

  loadCSS = function (href) {

    var cssLink = $("<link>");
    $("head .main-stylesheet").before(cssLink);

    cssLink.attr({
      rel: "stylesheet",
      type: "text/css",
      href: href
    });

  };

  jQuery.loadCachedScript = function (url, options) {

    options = $.extend(options || {}, {
      dataType: "script",
      cache: true,
      url: url
    });

    return jQuery.ajax(options);
  };

  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


})(jQuery); // Fully reference jQuery after this point.




